import React, { FC } from 'react';

import { Col, Container, Row } from 'layout';
import DownloadLink from 'components/DownloadLink';
import SingleCta from 'components/SingleCta';

import { BannerWithResultsCardProps } from './models';

import './BannerWithResultsCard.scss';

const BannerWithResultsCard: FC<BannerWithResultsCardProps> = ({
  pageTitle,
  cardImage,
  cardTag,
  cardTitle,
  cardDescription,
  cardCtaLink,
  pdfReportDownload,
}) => {
  const singleCta = {
    module: {
      image: cardImage,
      sectionTag: cardTag,
      title: cardTitle,
      text: cardDescription,
      link: cardCtaLink,
      isBackgroundWhiteAndGrey: false,
    },
  };

  return (
    <section className="results-banner">
      <DownloadLink pdfReportDownload={pdfReportDownload} />
      <div className="results-banner__top">
        <Container>
          <Row>
            <Col sm="12" xl="10" className="col--no-gutters">
              <h1 className="results-banner__title h1">{pageTitle}</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <SingleCta {...singleCta} subModule />
    </section>
  );
};

export default BannerWithResultsCard;
