import React, { FC, useContext } from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import ExternalLink from 'common/ExternalLink';

import ScreenRecognitionContext from '../../contexts/screenRecognitionContext';
import XTwitter from '../icons/x-twitter.svg';
import { ContactSocialMediaProps } from './models';

import './ContactSocialMedia.scss';

const ContactSocialMedia: FC<ContactSocialMediaProps> = ({ followBrandText, socialLinks }) => {
  const { isSmallMobile, isMobile, isDesktop, isLargeDesktop } =
    useContext(ScreenRecognitionContext);

  const classForSpan = classNames('contact-social-media__title-follow', {
    h6: !isSmallMobile && !isMobile,
    'tag tag--bold': isSmallMobile || isMobile,
  });

  const classesSocialBtns = classNames('contact-social-media__link', {
    link: isDesktop || isLargeDesktop,
  });

  return (
    <div className="contact-social-media">
      <h2 className={classForSpan}>{followBrandText}</h2>
      <ul className="contact-social-media__list">
        {socialLinks?.map(({ url, icon, name }) => (
          <li key={icon[0]} className="contact-social-media__item">
            <ExternalLink className={classesSocialBtns} url={url} dataTestId={icon[0]} title={name}>
              {isDesktop || isLargeDesktop ? (
                <span className="contact-social-media__icon-title">{name}</span>
              ) : null}
              <span className="contact-social-media__icon-wrap">
                {icon[0] === 'xtwitter' ? (
                  <XTwitter />
                ) : (
                  <FontAwesomeIcon
                    titleId={icon[0]}
                    aria-labelledby={icon[0]}
                    className="fa"
                    icon={['fab', icon[0] as IconName]}
                  />
                )}
              </span>
            </ExternalLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContactSocialMedia;
