import React, { FC } from 'react';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';
import ContactSocialMedia from 'common/ContactSocialMedia';

import BannerLogo from '../../../common/icons/banner-icon.svg';
import { BrandsAndSocialMediaBannerWrapperProps } from './models';

import './BrandsAndSocialMediaBannerWrapper.scss';

const BrandsAndSocialMediaBannerWrapper: FC<BrandsAndSocialMediaBannerWrapperProps> = ({
  children,
  followBrandText,
  socialMediaLinks,
  socialBanner,
}) => {
  const sectionClasses = classNames('brandsAndSocialMediaBanner', {
    'brandsAndSocialMediaBanner--brands': !socialBanner,
    'brandsAndSocialMediaBanner--social': socialBanner,
  });

  return (
    <section className={sectionClasses}>
      <div className="brandsAndSocialMediaBanner__banner-logo-wrapp">
        <BannerLogo className="brandsAndSocialMediaBanner__banner-logo" aria-hidden="true" />
      </div>
      <Container>
        <Row>
          <Col xl="12" md="12" sm="12" className="col--no-gutters">
            {children}
            {socialMediaLinks && socialMediaLinks.length > 0 ? (
              <ContactSocialMedia
                followBrandText={followBrandText}
                socialLinks={socialMediaLinks}
              />
            ) : null}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BrandsAndSocialMediaBannerWrapper;
