import React, { FC } from 'react';

import { Col } from 'layout';

import { BehaviourAndStrategyBoxesItemProps } from './models';

import './BehaviourAndStrategyBoxesItem.scss';

const BehaviourAndStrategyBoxesItem: FC<BehaviourAndStrategyBoxesItemProps> = ({ title, text }) => (
  <Col element="li" xl="3" md="6" sm="12" className="behaviour-boxes__item col--no-gutters">
    <div className="behaviour-boxes__item-holder">
      <h3 className="behaviour-boxes__item-title">{title}</h3>
      <div
        className="rte behaviour-boxes__item-text caption"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  </Col>
);

export default BehaviourAndStrategyBoxesItem;
