import React, { FC, useEffect, useMemo, useState } from 'react';

import CustomDropdown from 'common/CustomDropdown';

import { CareersBannerDropdownProps } from './models';

const CareersBannerDropdown: FC<CareersBannerDropdownProps> = ({
  filter,
  locationQueryHandler,
}) => {
  const [location, setLocation] = useState(['']);
  const mergeLocationString = useMemo(() => {
    if (!location) return;

    return location.join('+').replace(/ /g, '+');
  }, [location]);
  const locationQuery = useMemo(
    () => (mergeLocationString ? `locationsearch=${mergeLocationString}` : ''),
    [location]
  );

  const selectHandler = (items) => {
    setLocation(items);
  };

  useEffect(() => {
    locationQueryHandler(locationQuery);
  }, [locationQuery]);

  return (
    <div className="careers-banner__wrap">
      <span className="careers-banner__label">{filter.label}</span>
      <CustomDropdown
        items={filter.locations}
        onSelect={selectHandler}
        initialSelection={[filter.dropdownText]}
        multiselect
      />
    </div>
  );
};

export default CareersBannerDropdown;
