import React, { FC, useContext, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';
import { commonColorHandler } from 'utils/colorsHandler';

import ScreenRecognitionContext from '../../../contexts/screenRecognitionContext';
import Slides from '../Slides';
import SlidesPreview from '../SlidesPreview';
import { SlidesWrapperProps } from './models';

import './SlidesWrapper.scss';

const SlidesWrapper: FC<SlidesWrapperProps> = ({ title, backgroundColor, items }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const { isSmallMobile, isMobile } = useContext(ScreenRecognitionContext);

  const {
    commonFields: { sliderPrevButtonText, sliderNextButtonText },
  } = useStaticQuery(graphql`
    {
      commonFields {
        sliderPrevButtonText
        sliderNextButtonText
      }
    }
  `);

  const newItemsWithShorterTitle = items.map((item) => {
    if (item.articleTitle.length > 52) {
      return {
        ...item,
        articleTitle: `${item.articleTitle.slice(0, 52)}...`,
      };
    }

    return item;
  });

  const nextSlide = () => {
    if (activeSlide < newItemsWithShorterTitle.length - 1) {
      setActiveSlide((prevState) => prevState + 1);
    } else {
      setActiveSlide(0);
    }
  };

  const prevSlide = () => {
    if (activeSlide === 0) {
      setActiveSlide(() => newItemsWithShorterTitle.length - 1);
    } else {
      setActiveSlide((prevState) => prevState - 1);
    }
  };

  const prevSlideHandle = () => {
    prevSlide();
  };

  const nextSlideHandle = () => {
    nextSlide();
  };

  const onActiveSlidePreviewHandle = (index: number) => {
    setActiveSlide(index);
  };

  return (
    <section
      className={classNames('caseStudySlider', commonColorHandler(backgroundColor[0]), {
        'caseStudySlider--no-slide': newItemsWithShorterTitle.length < 2,
        'caseStudySlider--bg': commonColorHandler(backgroundColor[0]) !== 'caseStudySlider--white',
        'rte-light-pink': commonColorHandler(backgroundColor[0]) === 'caseStudySlider--pink',
        'rte-white': commonColorHandler(backgroundColor[0]) === 'caseStudySlider--white',
        'rte-black': commonColorHandler(backgroundColor[0]) === 'caseStudySlider--black',
        'rte-light-grey': commonColorHandler(backgroundColor[0]) === '',
      })}
    >
      <Container>
        <div>
          <Row
            className={classNames('caseStudySlider__slides-row', {
              'caseStudySlider__no-slides': newItemsWithShorterTitle.length < 2,
            })}
          >
            <Slides
              title={title}
              slides={newItemsWithShorterTitle}
              activeSlide={activeSlide}
              color={backgroundColor[0]}
            />
          </Row>
        </div>
        {!isMobile && !isSmallMobile && newItemsWithShorterTitle.length > 1 ? (
          <>
            <Row>
              <Col xl="7" md="12" sm="12" className="col--no-gutters">
                <div className="caseStudySlider__carousel-btns">
                  <button
                    className="caseStudySlider__carousel-btn control-arrow control-prev"
                    onClick={prevSlideHandle}
                    type="button"
                    data-testid="case-study-slider-prev-button"
                  >
                    <span className="sr-only">{sliderPrevButtonText}</span>
                  </button>
                  <button
                    className="caseStudySlider__carousel-btn control-arrow control-next"
                    onClick={nextSlideHandle}
                    type="button"
                    data-testid="case-study-slider-next-button"
                  >
                    <span className="sr-only">{sliderNextButtonText}</span>
                  </button>
                </div>
              </Col>
            </Row>
            <ul className="row caseStudySlider__carousel-row row--start" role="tablist">
              <SlidesPreview
                slides={newItemsWithShorterTitle}
                activeSlide={activeSlide}
                onActiveSlidePreview={onActiveSlidePreviewHandle}
              />
            </ul>
          </>
        ) : null}
      </Container>
    </section>
  );
};

export default SlidesWrapper;
