import React, { Fragment, useCallback, useMemo, useState } from 'react';

import { Col, Container, Row } from 'layout';
import CareersBannerDropdown from 'components/CareersBanner/CareersBannerDropdown';

import { CareersBannerFiltersProps } from './models';

const CareersBannerFilters: React.FC<CareersBannerFiltersProps> = ({ filters, buttonText }) => {
  const [search, setSearch] = useState('');
  const [locationQuery, setLocationQuery] = useState('');
  const careersURL = process.env.GATSBY_CAREERS_URL as string;

  const searchHandler = (e) => {
    setSearch(e.target.value.replace(/ /g, '+'));
  };

  const locationQueryHandler = (items) => {
    setLocationQuery(items);
  };

  const seachQuery = useMemo(() => (search ? `q=${search}&` : ''), [search]);

  const SearchInput = (filter) => (
    <div className="careers-banner__wrap">
      <label htmlFor="careers-banner-search" className="careers-banner__label">
        {filter.label}
      </label>
      <input
        className="careers-banner__search"
        type="text"
        onChange={(e) => searchHandler(e)}
        id="careers-banner-search"
        placeholder={filter.placeholder}
      />
    </div>
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!careersURL) return;
      window.open(`${careersURL}?${seachQuery}${locationQuery}`, '_blank');
    },
    [seachQuery, locationQuery]
  );

  return (
    <Container>
      <Row>
        <Col>
          <form className="careers-banner__bottom" onSubmit={handleSubmit}>
            <div className="careers-banner__filters">
              {filters.map((filter) => (
                <Fragment key={filter.__typename}>
                  {filter.__typename === 'CareersBannerSearch' ? (
                    SearchInput(filter)
                  ) : (
                    <CareersBannerDropdown
                      filter={filter}
                      locationQueryHandler={locationQueryHandler}
                    />
                  )}
                </Fragment>
              ))}
            </div>
            {filters && buttonText ? (
              <button className="btn btn--pink careers-banner__btn" type="submit">
                {buttonText}
              </button>
            ) : null}
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default CareersBannerFilters;
