import React, { FC, useContext, useEffect, useState } from 'react';

import { Col } from 'layout';
import CustomCarousel from 'components/CustomCarousel';
import { formatDisplayDateForInvestors } from 'utils/dateHandler';
import { penniesToPounds } from 'utils/services/sharePriceService';
import { replaceLast } from 'utils/stringUtils/stringUtils';

import ScreenRecognitionContext from '../../../contexts/screenRecognitionContext';
import { useSharePriceContext } from '../../../contexts/sharePriceContext';
import InvestorsBannerCard from './InvestorsBannerCard';
import { InvestorsBannerCardsProps } from './models';

import './InvestorsBannerCards.scss';

const InvestorsBannerCards: FC<InvestorsBannerCardsProps> = ({
  factCard,
  sharePriceCard,
  eventCard,
}) => {
  const { isMobile, isSmallMobile } = useContext(ScreenRecognitionContext);
  const [cards, setCards]: any = useState([]);
  const sharePriceData = useSharePriceContext();

  const formatDate = (date) => {
    const formattedDate = replaceLast(formatDisplayDateForInvestors(date, 'en-gb'), /\s/g, " '");
    const yearAndMonthDate = formattedDate.split(' ').slice(1, formattedDate.length).join(' ');

    return eventCard?.displayOnlyMonth ? yearAndMonthDate : formattedDate;
  };

  useEffect(() => {
    if (sharePriceData.data?.currentPrice === undefined) return;
    setCards([
      {
        tag: factCard.tag,
        title: factCard.fact,
        description: factCard.supportingText,
        link: factCard.link,
      },
      {
        tag: sharePriceCard.tag,
        title: `£${penniesToPounds(sharePriceData.data?.currentPrice)}`,
        description: `${penniesToPounds(sharePriceData.data?.change)} ${
          sharePriceData.data?.percentageChange
        }%`,
        link: sharePriceCard.link,
      },
      {
        tag: eventCard?.tag,
        title: formatDate(eventCard?.eventDate),
        description: eventCard?.eventTitle,
        displayOnlyMonth: eventCard?.displayOnlyMonth,
        link: eventCard?.link,
      },
    ]);
  }, [sharePriceData]);

  return (
    <>
      {isMobile || isSmallMobile ? (
        <CustomCarousel
          autoPlay
          infiniteLoop
          showArrows={false}
          showStatus={false}
          showThumbs={false}
        >
          {cards?.map((card) => (
            <InvestorsBannerCard {...card} key={card.title} />
          ))}
        </CustomCarousel>
      ) : (
        <>
          {cards?.map((card) => (
            <Col className="investors-banner__col col--no-gutters" key={card.title} lg="4" md="4">
              <InvestorsBannerCard {...card} />
            </Col>
          ))}
        </>
      )}
    </>
  );
};

export default InvestorsBannerCards;
