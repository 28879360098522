import React, { FC } from 'react';

import Image from 'common/Image';
import ArticlePreviewFeaturedImage from 'common/images/ArticlePreviewFeaturedImage';

import { HeroBannerImageSlideProps } from './models';

const HeroBannerImageSlide: FC<HeroBannerImageSlideProps> = ({ slide, defaultImage }) => {
  const HeroBannerArticleImage = (slideData, fallbackImage) => {
    if (!slideData?.previewImage && !defaultImage) return;

    return slideData?.previewImage ? (
      <ArticlePreviewFeaturedImage
        className="hero-banner__slides"
        imageData={slideData?.previewImage}
        alt={slideData?.previewImage?.altText}
      />
    ) : (
      <Image className="hero-banner__slides" imageData={defaultImage} alt={fallbackImage.altText} />
    );
  };

  const HeroBannerCustomImage = (slideData) => {
    if (!slideData?.image) return;

    return (
      <Image
        className="hero-banner__slides"
        imageData={slideData?.image}
        alt={slideData?.image?.altText}
      />
    );
  };

  return (
    <>
      {slide.__typename === 'ArticlePagePreviewType'
        ? HeroBannerArticleImage(slide, defaultImage)
        : HeroBannerCustomImage(slide)}
    </>
  );
};

export default HeroBannerImageSlide;
