import { ModulesContent } from '../../@types/content/modules';
import { SlidesWrapperItemType } from './SlidesWrapper/models';

export const getNestedItems = (
  slidesParent: ModulesContent.CaseStudySliderNestedItemsType
): SlidesWrapperItemType[] =>
  slidesParent.items.map((item) => {
    const {
      articleTitle,
      descriptionText,
      expandForMoreText,
      image,
      video,
      captionText,
      playVideoButtonText,
      ctaLink,
    } = item;
    const { expandButtonText, collapseButtonText } = slidesParent;

    return {
      articleTitle,
      descriptionText,
      expandForMoreText,
      image,
      video,
      captionText,
      playVideoButtonText,
      expandButtonText,
      collapseButtonText,
      ctaLink,
    };
  });

export const getLinkButtonClass = (color: string): string =>
  `caseStudySlider__btn-more link ${
    color === 'caseStudySlider--black'
      ? 'link--white-white'
      : color === 'caseStudySlider--white'
      ? 'link--pink'
      : 'link--black-white'
  }`;

export const getBtnClasses = (color: string): string =>
  `${getLinkButtonClass(color)} caseStudySlider__expand-colapse`;

export const getLinkClasses = (color: string): string =>
  `${getLinkButtonClass(color)} caseStudySlider__btn-text--expand`;

export const getMainImageAndVideoFromArticle = (mainImageAndVideoSlider) => {
  if (!mainImageAndVideoSlider) return null;

  const { image, video, caption } = mainImageAndVideoSlider.items[0];

  return { image, video, caption };
};
