import React, { FC } from 'react';

import BrandsAndSocialMediaBannerWrapper from '../BrandsAndSocialMediaBannerWrapper';
import { SocialMediaBannerProps } from './models';

import './SocialMediaBanner.scss';

const SocialMediaBanner: FC<SocialMediaBannerProps> = ({
  pageTitle,
  followBrandText,
  socialMediaLinks,
}) => (
  <BrandsAndSocialMediaBannerWrapper
    followBrandText={followBrandText}
    socialMediaLinks={socialMediaLinks}
    socialBanner
  >
    <h1 className="social-media-banner__title h1">{pageTitle}</h1>
  </BrandsAndSocialMediaBannerWrapper>
);

export default SocialMediaBanner;
