import React, { FC } from 'react';

import Image from 'common/Image';
import LinkItem from 'common/LinkItem';

import BrandsAndSocialMediaBannerWrapper from '../BrandsAndSocialMediaBannerWrapper';
import { BrandsBannerProps } from './models';

import './BrandsBanner.scss';

const BrandsBanner: FC<BrandsBannerProps> = ({ brand, visitSiteButtonText, followBrandText }) => {
  const { image, brandSite, tagName, socialMediaLinks } = brand[0];

  return (
    <BrandsAndSocialMediaBannerWrapper
      followBrandText={followBrandText}
      socialMediaLinks={socialMediaLinks}
      socialBanner={false}
    >
      {brandSite ? (
        <LinkItem
          link={brandSite}
          linkClass="brands-banner__link link link--download"
          title={visitSiteButtonText}
          hasIcon
          iconClass="brands-banner__link-icon"
          isLink
          showTitle
        />
      ) : null}
      <Image className="brands-banner__image" imageData={image} alt={image.altText} />
      <h1 className="brands-banner__title h1">{tagName}</h1>
    </BrandsAndSocialMediaBannerWrapper>
  );
};

export default BrandsBanner;
