import React, { FC, useRef } from 'react';
import { CommonTypes } from '@shared/types/base/common';
import objectHash from 'object-hash';

import { Col } from 'layout';
import Caption from 'common/Caption';
import CaseStudyVideoOrImage from 'components/CaseStudySlider/CaseStudyVideoOrImage';
import SlideDescription from 'components/CaseStudySlider/SlideDescription';

import { SlideProps } from './models';

import './Slide.scss';

const Slide: FC<SlideProps> = ({ slide, title, index, activeSlide, color }) => {
  const isPulledModule = !(!slide.url && !slide.linkText);
  const ariaId: string = objectHash({ slide, title, index, activeSlide, color });

  const titleRef = useRef<HTMLHeadingElement>(null);

  let link: CommonTypes.LinkType[] = [];

  if (slide.url && slide.linkText) {
    link = [{ name: slide.linkText, url: slide.url, target: '' }];
  } else {
    link = slide.ctaLink as CommonTypes.LinkType[];
  }

  return activeSlide === index ? (
    <>
      <Col xl="5" md="12" className="col--no-gutters" data-atribute={index}>
        <span className="caseStudySlider__title caseStudySlider__animation">{title}</span>
        <h2 className="caseStudySlider__articleTitle h4 caseStudySlider__animation" ref={titleRef}>
          {slide.articleTitle}
        </h2>
        <SlideDescription
          titleRef={titleRef}
          descriptionText={slide.descriptionText}
          expandForMoreText={slide.expandForMoreText}
          isPulledModule={isPulledModule}
          expandButtonText={slide.expandButtonText}
          collapseButtonText={slide.collapseButtonText}
          color={color}
          link={link}
        />
      </Col>
      <Col xl="7" md="12" className="col--no-gutters">
        <CaseStudyVideoOrImage
          video={slide.video ? slide.video : ''}
          image={slide.image}
          playButtonText={slide.playVideoButtonText}
          imageClass="caseStudySlider__img"
          videoClass="caseStudySlider__video"
          isPulledModule={isPulledModule}
          ariaId={ariaId}
        />
        {slide.captionText ? <Caption text={slide.captionText} ariaId={ariaId} isRte /> : null}
      </Col>
    </>
  ) : null;
};

export default Slide;
