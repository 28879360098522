import React, { FC, useEffect, useState } from 'react';
import { Configure } from 'react-instantsearch-dom';
import { graphql, useStaticQuery } from 'gatsby';

import CustomResultsHandler from 'common/Filters/CustomResultsHandler';
import InstantSearchContainer from 'common/InstantSearchContainer';
import { getPulledNewsFilters } from 'utils/articlesFiltersHandler';
import { CASE_STUDY_SLIDER_DEFAULT_NUMBER } from 'utils/constants';

import { ModulesContent } from '../../@types/content/modules';
import CaseStudySliderCustomHits from './CaseStudySliderCustomHits';
import { CaseStudySliderProps } from './models';
import SlidesWrapper from './SlidesWrapper';
import { getNestedItems } from './utils';

const CaseStudySlider: FC<CaseStudySliderProps> = ({ module }) => {
  const {
    commonFields: { pulledArticlesNoResultsText },
  } = useStaticQuery(graphql`
    {
      commonFields {
        pulledArticlesNoResultsText
      }
    }
  `);

  const { slides, title, backgroundColor } = module;
  const [filters, setFilters] = useState<string>('');
  const [pulledSlides, setPulledSlides] = useState<ModulesContent.CaseStudySliderPulledItemsType>();

  useEffect(() => {
    if (slides[0].__typename === 'CaseStudySliderPulledItems') {
      const pulledItems = module.slides[0] as ModulesContent.CaseStudySliderPulledItemsType;
      setFilters(
        getPulledNewsFilters(pulledItems.categoryTags, [pulledItems.parentPageId], pulledItems.lang)
      );
      setPulledSlides(pulledItems);
    }
  }, []);

  if (slides[0].__typename === 'CaseStudySliderNestedItems') {
    return (
      <SlidesWrapper
        title={title}
        backgroundColor={module.backgroundColor}
        items={getNestedItems(module.slides[0] as ModulesContent.CaseStudySliderNestedItemsType)}
      />
    );
  }

  return (
    <InstantSearchContainer indexName={`${process.env.GATSBY_ALGOLIA_INDEX}-articles`}>
      <Configure hitsPerPage={CASE_STUDY_SLIDER_DEFAULT_NUMBER} filters={filters} />
      <CustomResultsHandler noResultsText={pulledArticlesNoResultsText}>
        <CaseStudySliderCustomHits
          title={title}
          backgroundColor={backgroundColor}
          playVideoButtonText={pulledSlides?.playVideoButtonText}
          linkText={pulledSlides?.linkText}
        />
      </CustomResultsHandler>
    </InstantSearchContainer>
  );
};

export default CaseStudySlider;
