import React, { FC } from 'react';
import classNames from 'classnames';
import objectHash from 'object-hash';

import { HeroBannerPaginationProps } from './models';

const HeroBannerPagination: FC<HeroBannerPaginationProps> = ({
  items,
  setCurrentSlide,
  setIsPausedSlider,
  setRestartAnimation,
  currentSlide,
}) => {
  const paginationButtonHandler = (index) => {
    setCurrentSlide(index);
    setIsPausedSlider(false);
    setRestartAnimation('disabled-animation');
  };

  return (
    <ul className="hero-banner__pagination">
      {items.map((_, index) => (
        <li className="hero-banner__list-item" key={objectHash({ pagination: index })}>
          <button
            type="button"
            className={classNames('hero-banner__dot', {
              'hero-banner__dot--selected': currentSlide === index,
            })}
            onClick={() => paginationButtonHandler(index)}
          >
            <span className="sr-only">{`pagination Slide ${index}`}</span>
          </button>
        </li>
      ))}
    </ul>
  );
};

export default HeroBannerPagination;
