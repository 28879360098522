import React, { FC, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import objectHash from 'object-hash';

import Arrow from 'common/icons/arrow-right.svg';
import HeroBannerBox from 'components/HeroBanner/HeroBannerBox';
import HeroBannerPagination from 'components/HeroBanner/HeroBannerPagination';

import { HeroBannerArticleProps } from './models';

import './HeroBannerArticle.scss';

const HeroBannerArticle: FC<HeroBannerArticleProps> = ({
  items,
  currentSlide,
  nextSlide,
  setCurrentSlide,
  playText,
  pauseText,
  nextSlideText,
}) => {
  const [restartAnimation, setRestartAnimation] = useState('active-animation');
  const [isPausedSlider, setIsPausedSlider] = useState(false);

  const nextArticle = useMemo(() => {
    if (items?.length <= 1) return;
    if (currentSlide >= items.length - 1) {
      return items[0]?.title;
    }

    return items[currentSlide + 1].title;
  }, [currentSlide]);

  const nextButtonHandler = () => {
    nextSlide();
    setIsPausedSlider(false);
    setRestartAnimation('disabled-animation');
  };

  useEffect(() => {
    if (restartAnimation !== 'disabled-animation') return;
    setRestartAnimation('active-animation');
  }, [restartAnimation]);

  return (
    <div className="hero-banner__bottom">
      <div className="hero-banner__box-wrap">
        {items.map((slide, index) => (
          <HeroBannerBox
            className={classNames('hero-banner__box', {
              'hero-banner__box--visible': currentSlide === index,
            })}
            key={objectHash({ imageSlide: index })}
            slide={slide}
            setIsPausedSlider={setIsPausedSlider}
            isPausedSlider={isPausedSlider}
            numberOfSlides={items.length}
            playText={playText}
            pauseText={pauseText}
          />
        ))}
      </div>
      {items.length > 1 ? (
        <HeroBannerPagination
          items={items}
          setCurrentSlide={setCurrentSlide}
          setIsPausedSlider={setIsPausedSlider}
          setRestartAnimation={setRestartAnimation}
          currentSlide={currentSlide}
        />
      ) : null}
      {items.length > 1 ? (
        <div className="hero-banner__next-slide">
          {nextSlideText ? (
            <div className="hero-banner__tag-wrap">
              <span className="hero-banner__next-tag">{nextSlideText}</span>
            </div>
          ) : null}
          <button type="button" className="hero-banner__next" onClick={nextButtonHandler}>
            {nextArticle ? <span className="hero-banner__next-title">{nextArticle}</span> : null}
            <Arrow className="hero-banner__icon" />
          </button>
          <div className="hero-banner__progress-wrap">
            <div
              key={restartAnimation}
              onAnimationIteration={nextSlide}
              className={classNames('hero-banner__progress', {
                'hero-banner__progress--paused': isPausedSlider,
              })}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default HeroBannerArticle;
