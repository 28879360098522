import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import objectHash from 'object-hash';

import { Col, Container, Row } from 'layout';
import Caption from 'common/Caption';
import Image from 'common/Image';
import DownloadLink from 'components/DownloadLink';
import VideoIframe from 'components/VideoIframe';
import { mediaBannerColorHandler } from 'utils/colorsHandler';

import Icon from '../../common/icons/arrow-right.svg';
import BannerLogo from '../../common/icons/banner-icon.svg';
import { MediaBannerProps } from './models';

import './MediaBanner.scss';

const MediaBanner: FC<MediaBannerProps> = ({
  subheading,
  headlineText,
  backgroundColor,
  bannerImage,
  bannerVideo,
  captionText,
  playVideoButtonText,
  scrollButtonText,
  summaryText,
  pdfReportDownload,
}) => {
  let topPosition: number | undefined;
  const ariaId: string = objectHash({
    subheading,
    headlineText,
    backgroundColor,
    bannerImage,
    bannerVideo,
    captionText,
    playVideoButtonText,
    scrollButtonText,
    summaryText,
    pdfReportDownload,
  });

  const Logo = () => {
    if (backgroundColor[0] !== 'banner-media--gradient') return null;

    return (
      <div className="banner-media__banner-logo-wrapp">
        <BannerLogo className="banner-media__banner-logo" alt="Company logo" />
      </div>
    );
  };

  const scrollHandle = () => {
    window.scrollTo({
      top: topPosition,
    });
  };

  const SubHeading = () => {
    if (!subheading) return null;

    return <span className="banner-media__subtitle h6">{subheading}</span>;
  };

  const Button = () => {
    if (!scrollButtonText) return null;

    return (
      <button
        className={classNames('banner-media__scroll scroll-btn', {
          'yellow-outline':
            (mediaBannerColorHandler(backgroundColor[0]) === 'banner-media--pink' ||
              mediaBannerColorHandler(backgroundColor[0]) === 'banner-media--gradient') &&
            (!bannerImage || !bannerVideo),
          'inner-outline': bannerImage || bannerVideo,
        })}
        type="button"
        aria-label="scroll to the next section"
        onClick={scrollHandle}
      >
        <span className="banner-media__scroll-text breadcrumb">{scrollButtonText}</span>
        <Icon className="banner-media__scroll-icon" aria-hidden="true" focusable="false" />
      </button>
    );
  };

  const SummaryText = () => {
    if (!summaryText) return null;

    return <p className="banner-media__text standfirst">{summaryText}</p>;
  };

  useEffect(() => {
    const modules = document?.querySelector('#modules');
    topPosition = modules?.getBoundingClientRect().top;
  }, []);

  return (
    <section className={`banner-media ${mediaBannerColorHandler(backgroundColor[0])}`}>
      <DownloadLink pdfReportDownload={pdfReportDownload} />
      <div className="banner-media__top">
        <Logo />
        <Container>
          <Row>
            <Col xl="12" md="12" sm="12" className="banner-media__container col--no-gutters">
              <SubHeading />
              <h1 className="banner-media__title h1">{headlineText}</h1>
              <SummaryText />
              <Button />
            </Col>
          </Row>
        </Container>
      </div>
      {!bannerImage && !bannerVideo ? null : (
        <div className="banner-media__bottom">
          <Container>
            <Row>
              <Col xl="12" md="12" sm="12" className="banner-media__container col--no-gutters">
                {bannerVideo ? (
                  <VideoIframe
                    url={bannerVideo}
                    placeholderImage={bannerImage}
                    playButtonText={playVideoButtonText}
                    ariaId={ariaId}
                  />
                ) : (
                  <Image
                    className="banner-media__image"
                    imageData={bannerImage}
                    alt={bannerImage.altText}
                    aria-describedby={ariaId}
                  />
                )}
              </Col>
              {captionText ? <Caption text={captionText} ariaId={ariaId} isRte /> : null}
            </Row>
          </Container>
        </div>
      )}
    </section>
  );
};
export default MediaBanner;
