import React, { FC } from 'react';

import BannerWithCommitmentCards from 'components/BannerWithCommitmentCards';
import BannerWithoutCards from 'components/BannerWithoutCards';
import BannerWithResultsCard from 'components/BannerWithResultsCard';
import BannerWithSimpleCards from 'components/BannerWithSimpleCards';
import BrandsAndSocialMediaBanner from 'components/BrandsAndSocialMediaBanner';
import CareersBanner from 'components/CareersBanner';
import HeroBanner from 'components/HeroBanner';
import InvestorsBanner from 'components/InvestorsBanner';
import MediaBanner from 'components/MediaBanner';
import SimpleBanner from 'components/SimpleBanner';

import { BannerRendererProps } from './models';

const Components = {
  BannerWithoutCards,
  BannerWithSimpleCards,
  BannerWithCommitmentCards,
  MediaBanner,
  BrandsAndSocialMediaBanner,
  SimpleBanner,
  BannerWithResultsCard,
  InvestorsBanner,
  CareersBanner,
  HeroBanner,
};

const BannerRenderer: FC<BannerRendererProps> = (banner) => {
  if (typeof Components[banner.__typename] === 'undefined') {
    return null;
  }

  const Component = Components[banner.__typename];

  return <Component key={banner.__typename} {...banner} />;
};

export default BannerRenderer;
