import React, { FC, useContext } from 'react';

import CustomCarousel from 'components/CustomCarousel';

import ScreenRecognitionContext from '../../../contexts/screenRecognitionContext';
import { SlidesProps } from './models';
import Slide from './Slide';

import './Slides.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Slides: FC<SlidesProps> = ({ title, slides, activeSlide, color }) => {
  const { isSmallMobile, isMobile } = useContext(ScreenRecognitionContext);

  return isMobile || isSmallMobile ? (
    <CustomCarousel
      autoPlay
      infiniteLoop
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      showIndicators={slides.length > 1}
    >
      {slides?.map((slide) => (
        <Slide slide={slide} title={title} key={slide.articleTitle} color={color} />
      ))}
    </CustomCarousel>
  ) : (
    <>
      {slides?.map((slide, index) => (
        <Slide
          slide={slide}
          title={title}
          key={slide.articleTitle}
          index={index}
          activeSlide={activeSlide}
          color={color}
        />
      ))}
    </>
  );
};

export default Slides;
