import React, { FC, useEffect, useState } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';

import SlidesWrapper from '../SlidesWrapper';
import { getMainImageAndVideoFromArticle } from '../utils';
import { CaseStudySliderCustomHitsProps } from './models';

const InfiniteHits = ({ hits, title, backgroundColor, playVideoButtonText, linkText }) => {
  const [newHits, setNewHits] = useState([]);

  useEffect(() => {
    const transformedHits =
      hits?.map((hit) => {
        const mainImageAndVideo = getMainImageAndVideoFromArticle(hit.mainImageAndVideoSlider);

        return {
          articleTitle: hit.title,
          descriptionText: hit.previewText,
          url: hit.url,
          image: hit.previewImage || mainImageAndVideo?.image,
          video: hit.previewImage ? null : mainImageAndVideo?.video,
          captionText: hit.previewImage ? null : mainImageAndVideo?.caption,
          playVideoButtonText,
          linkText,
        };
      }) || [];

    setNewHits(transformedHits);
  }, []);

  return <SlidesWrapper title={title} backgroundColor={backgroundColor} items={newHits} />;
};

const CaseStudySliderCustomHits: FC<CaseStudySliderCustomHitsProps> =
  connectInfiniteHits(InfiniteHits);
export default CaseStudySliderCustomHits;
