import React, { FC } from 'react';

import BrandsBanner from './BrandsBanner';
import { BrandsBannerProps } from './BrandsBanner/models';
import { BrandsAndSocialMediaBannerProps } from './models';
import SocialMediaBanner from './SocialMediaBanner';
import { SocialMediaBannerProps } from './SocialMediaBanner/models';

const BrandsAndSocialMediaBanner: FC<BrandsAndSocialMediaBannerProps> = ({ banner }) => (
  <section>
    {Object.prototype.hasOwnProperty.call(banner[0], 'brand') ? (
      <BrandsBanner {...(banner[0] as BrandsBannerProps)} />
    ) : (
      <SocialMediaBanner {...(banner[0] as SocialMediaBannerProps)} />
    )}
  </section>
);

export default BrandsAndSocialMediaBanner;
