import React, { FC } from 'react';
import { GatsbyImage, getImage, IGatsbyImageData, withArtDirection } from 'gatsby-plugin-image';

import { ImagesContent } from '../../../@types/content/images';
import { getArtDirectedArticlePreviewFeaturedImages } from './utils';

const defaultBreakpoints: ImagesContent.ArticlePreviewFeaturedImageBreakpoint[] = [
  { alias: 'featuredArticleMobile', media: '(max-width: 767px)' },
  { alias: 'featuredArticleTablet', media: '(max-width: 1199px)' },
];

const ArticlePreviewFeaturedImage: FC<ImagesContent.ArticlePreviewFeaturedImageProps> = ({
  imageData,
  alt,
  breakpoints = defaultBreakpoints,
  ...restProps
}) => {
  const isArtDirected = Object.keys(imageData).includes('newsGridDesktop');
  const mainImage = isArtDirected
    ? getImage((imageData as ImagesContent.ArticlePreviewFeaturedImage).featuredArticleDesktop)
    : getImage(imageData as ImagesContent.ImageDataLike);

  const images = withArtDirection(
    mainImage as IGatsbyImageData,
    getArtDirectedArticlePreviewFeaturedImages(
      breakpoints,
      imageData as ImagesContent.ArticlePreviewFeaturedImage
    )
  );

  return <GatsbyImage image={images} alt={alt} {...restProps} />;
};

export const createImageWithBreakpoints =
  (
    breakpoints: ImagesContent.ArticlePreviewFeaturedImageBreakpoint[]
  ): FC<ImagesContent.ArticlePreviewFeaturedImageProps> =>
  (props: ImagesContent.ArticlePreviewFeaturedImageProps) =>
    <ArticlePreviewFeaturedImage {...props} breakpoints={breakpoints} />;

export default ArticlePreviewFeaturedImage;
