import React, { FC } from 'react';

import Image from 'common/Image';
import ArticleCaseStudiesImage from 'common/images/ArticleCaseStudiesImage';

import { CaseStudyImageProps } from './models';

const CaseStudyImage: FC<CaseStudyImageProps> = ({ image, imageClass, isPulledModule, ariaId }) => {
  if (!image) return null;

  return isPulledModule ? (
    <ArticleCaseStudiesImage
      imageData={image}
      alt={image.altText}
      className={imageClass}
      aria-describedby={ariaId}
    />
  ) : (
    <Image imageData={image} alt={image.altText} className={imageClass} aria-describedby={ariaId} />
  );
};

export default CaseStudyImage;
