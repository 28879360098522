import React, { FC } from 'react';

import { Container, Row } from 'layout';

import BehaviourAndStrategyBoxesItem from './BehaviourAndStrategyBoxesItem';
import { BehaviourAndStrategyBoxesProps } from './models';

import './BehaviourAndStrategyBoxes.scss';

const BehaviourAndStrategyBoxes: FC<BehaviourAndStrategyBoxesProps> = ({ module }) => (
  <section className="section behaviour-boxes">
    <Container>
      <Row element="ul">
        {module.boxes?.map((box) => (
          <BehaviourAndStrategyBoxesItem {...box} key={box.title} />
        ))}
      </Row>
    </Container>
  </section>
);

export default BehaviourAndStrategyBoxes;
