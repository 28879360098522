import React, { FC } from 'react';

import { Col, Container, Row } from 'layout';
import BannerBase from 'components/BannerBase';
import DownloadLink from 'components/DownloadLink';

import InvestorsBannerCards from './InvestorsBannerCards';
import { InvestorsBannerProps } from './models';

import './InvestorsBanner.scss';

const InvestorsBanner: FC<InvestorsBannerProps> = ({
  title,
  factCard,
  sharePriceCard,
  eventCard,
  pdfReportDownload,
}) => (
  <section className="investors-banner">
    <DownloadLink pdfReportDownload={pdfReportDownload} />
    <div className="investors-banner__top">
      <Container>
        <Row>
          <Col xl="12" md="12" sm="12" className="investors-banner__container col--no-gutters">
            <BannerBase bannerClassName="investors-banner" headlineText={title} />
          </Col>
        </Row>
      </Container>
    </div>
    <div className="investors-banner__bottom">
      <Container>
        <Row>
          <InvestorsBannerCards
            factCard={factCard[0]}
            sharePriceCard={sharePriceCard[0]}
            eventCard={eventCard ? eventCard[0] : undefined}
          />
        </Row>
      </Container>
    </div>
  </section>
);

export default InvestorsBanner;
