import React, { FC, useState } from 'react';

import LinkItem from 'common/LinkItem';
import {
  getBtnClasses,
  getLinkButtonClass,
  getLinkClasses,
} from 'components/CaseStudySlider/utils';

import Minus from '../../../common/icons/minus.svg';
import Plus from '../../../common/icons/plus.svg';
import { SlideDescriptionProps } from './models';

import './SlideDescription.scss';

const SlideDescription: FC<SlideDescriptionProps> = ({
  titleRef,
  descriptionText,
  expandForMoreText,
  isPulledModule,
  expandButtonText,
  collapseButtonText,
  color,
  link,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const mouseEnterHandle = () => {
    titleRef.current?.classList.add('caseStudySlider__articleTitle--hover');
  };

  const mouseLeaveHandle = () => {
    titleRef.current?.classList.remove('caseStudySlider__articleTitle--hover');
  };

  const expandForMoreTextHandle = () => {
    setIsExpanded(!isExpanded);
  };

  return isPulledModule ? (
    <>
      <div className="caseStudySlider__description caseStudySlider__animation">
        <p>{descriptionText}</p>
      </div>
      <LinkItem
        link={link}
        linkClass={`${getLinkButtonClass(color)} caseStudySlider__animation`}
        titleClass="caseStudySlider__btn-text"
        onMouseEnter={mouseEnterHandle}
        onMouseLeave={mouseLeaveHandle}
        hasIcon
        isLink
        showTitle
      />
    </>
  ) : (
    <div className="caseStudySlider__description-container caseStudySlider__animation">
      <div
        className="caseStudySlider__description caseStudySlider__animation rte"
        dangerouslySetInnerHTML={{ __html: descriptionText }}
      />
      {!expandForMoreText ? (
        <LinkItem
          link={link}
          linkClass={`${getLinkButtonClass(color)} caseStudySlider__animation`}
          titleClass="caseStudySlider__btn-text"
          onMouseEnter={mouseEnterHandle}
          onMouseLeave={mouseLeaveHandle}
          hasIcon
          isLink
          showTitle
        />
      ) : null}
      {expandForMoreText ? (
        <>
          {!isExpanded ? (
            <button
              className={`${getBtnClasses(color)} caseStudySlider__animation`}
              type="button"
              onClick={expandForMoreTextHandle}
              aria-expanded={isExpanded}
            >
              {expandButtonText}
              <Plus aria-hidden="true" focusable="false" />
            </button>
          ) : null}
          {isExpanded ? (
            <>
              <div
                className="caseStudySlider__description caseStudySlider__animation rte"
                dangerouslySetInnerHTML={{ __html: String(expandForMoreText) }}
              />
              <LinkItem
                link={link}
                linkClass={`caseStudySlider__btn-text--expand ${getLinkClasses(
                  color
                )} caseStudySlider__animation`}
                titleClass="caseStudySlider__btn-text"
                onMouseEnter={mouseEnterHandle}
                onMouseLeave={mouseLeaveHandle}
                hasIcon
                isLink
                showTitle
              />
              <button
                className={`${getBtnClasses(color)} caseStudySlider__animation`}
                type="button"
                onClick={expandForMoreTextHandle}
                aria-expanded={isExpanded}
              >
                {collapseButtonText}
                <Minus aria-hidden="true" focusable="false" />
              </button>
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default SlideDescription;
