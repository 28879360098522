import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import Arrow from 'common/icons/dropdownArrow.svg';

import { CustomDropdownProps } from './models';

import './CustomDropdown.scss';

const CustomDropdown: FC<CustomDropdownProps> = ({
  items,
  label,
  onSelect,
  selectedItem,
  multiselect,
  initialSelection,
}) => {
  const [active, setActive] = useState<boolean>(false);
  const [selected, setSelected] = useState<number | string | string[]>(
    initialSelection ? [] : selectedItem
  );
  const dropdownRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLLIElement[]>([]);
  const btnRef = useRef<HTMLButtonElement>(null);

  const onDropdown = useCallback(() => {
    setActive((prevState) => {
      if (!prevState) optionsRef.current[0].focus();

      return !prevState;
    });
  }, [active]);

  useEffect(() => {
    if (!multiselect || !Array.isArray(selected)) return;
    onSelect(selected);
  }, [selected, multiselect]);

  const singleSelectHandlaer = (item) => {
    onSelect(item);
    setSelected(item);
    setActive(false);
  };

  const multiSelectHandler = (item) => {
    setSelected((prevState: any) => {
      if (prevState.includes(item)) {
        return prevState.filter((el) => el !== item);
      }

      return [...prevState, item];
    });
  };

  const handleSelect = useCallback(
    (item) => {
      if (!multiselect) {
        singleSelectHandlaer(item);
      } else {
        multiSelectHandler(item);
      }
    },
    [multiselect, selected]
  );

  const handleItemKeyDown = useCallback(
    (e, item) => {
      if (e.keyCode !== 13) return;
      e.preventDefault();
      handleSelect(item);
      if (btnRef.current) btnRef.current.focus();
    },
    [btnRef.current]
  );

  const handleKeyDown = (e) => {
    switch (true) {
      case e.keyCode === 9:
        if (
          active &&
          dropdownRef.current &&
          optionsRef.current &&
          optionsRef.current[optionsRef.current.length - 1] === e.target
        ) {
          setActive(false);
        }
        break;
      case e.shiftKey && e.keyCode === 9:
        if (active && dropdownRef.current && btnRef.current === e.target) {
          setActive(false);
        }
        break;
      case e.keyCode === 40:
        e.preventDefault();
        if (active && dropdownRef.current && optionsRef.current && e.target.nextSibling) {
          e.target.nextSibling.focus();
        }
        break;
      case e.keyCode === 38:
        e.preventDefault();
        if (active && dropdownRef.current && e.target.previousSibling) {
          e.target.previousSibling.focus();
        }
        break;
      default:
        break;
    }
  };

  const MultiSelectCheckbox = useCallback(
    (item) => {
      if (!multiselect || !Array.isArray(selected)) return;

      return selected.includes(item) ? (
        <span className="dropdown__checkbox dropdown__checkbox--checked" />
      ) : (
        <span className="dropdown__checkbox" />
      );
    },
    [selected, multiselect]
  );

  const multiselectBreadCrumb = useMemo(() => {
    if (!Array.isArray(selected)) return;

    return selected.length > 0 ? `${selected.length} selected` : initialSelection;
  }, [selected]);

  useEffect(() => {
    if (!selectedItem) return;
    setSelected(selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (active && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setActive(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [active]);

  return (
    <div className="dropdown">
      {!label ? null : <span className="dropdown__text breadcrumb">{label}</span>}
      <div
        ref={dropdownRef}
        onKeyDown={handleKeyDown}
        role="presentation"
        className={classNames('dropdown__container', {
          'dropdown__container--active': active,
        })}
      >
        <div className="dropdown__holder">
          <button
            className="dropdown__btn"
            aria-expanded={active}
            type="button"
            onClick={onDropdown}
            ref={btnRef}
          >
            <span className="breadcrumb">{multiselectBreadCrumb || selected}</span>
            <span className="dropdown__icon" aria-hidden="true">
              <Arrow className="dropdown__pic" />
            </span>
          </button>
          <ul
            role="listbox"
            className={classNames('dropdown__list', {
              'dropdown__list--active': active,
            })}
            tabIndex={-1}
            aria-label="List of years"
          >
            {items?.map((item, i) => (
              <li
                key={item}
                className={classNames('dropdown__item breadcrumb', {
                  'dropdown__item--active': selected === item,
                })}
                role="option"
                tabIndex={active ? 0 : -1}
                aria-selected={selected === item}
                onClick={() => handleSelect(item)}
                onKeyDown={(e) => {
                  handleItemKeyDown(e, item);
                }}
                ref={(btn: HTMLLIElement) => {
                  optionsRef.current[i] = btn;
                }}
              >
                {MultiSelectCheckbox(item)}
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CustomDropdown;
