import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import objectHash from 'object-hash';

import { Col, Container, Row } from 'layout';
import Caption from 'common/Caption';
import Image from 'common/Image';

import ScreenRecognitionContext from '../../contexts/screenRecognitionContext';
import { InfographicImageProps } from './models';

import './InfographicImage.scss';

const InfographicImage: FC<InfographicImageProps> = ({ module }) => {
  const { isSmallMobile, isMobile, isTablet, isDesktop, isLargeDesktop } =
    useContext(ScreenRecognitionContext);

  const ariaId: string = objectHash(module);

  return (
    <section className="infographic section-bg">
      <Container>
        <Row>
          <Col className="col--no-gutters">
            <h2
              className={classNames('infographic__title h4', {
                'sr-only': !module.showTitle,
              })}
            >
              {module.title}
            </h2>
          </Col>
        </Row>
      </Container>
      {isDesktop || isLargeDesktop ? (
        <Image
          className="infographic__img"
          imageData={module.desktopImage}
          alt={module.desktopImage.altText}
          aria-describedby={ariaId}
        />
      ) : null}
      {isTablet ? (
        <Image
          className="infographic__img"
          imageData={module.tabletImage}
          alt={module.tabletImage.altText}
          aria-describedby={ariaId}
        />
      ) : null}
      {isSmallMobile || isMobile ? (
        <Image
          className="infographic__img"
          imageData={module.mobileImage}
          alt={module.mobileImage.altText}
          aria-describedby={ariaId}
        />
      ) : null}
      {module.captionText ? (
        <Container>
          <Row className="row--center">
            <Col xl="10" className="col--no-gutters">
              <Caption text={module.captionText} ariaId={ariaId} />
            </Col>
          </Row>
        </Container>
      ) : null}
      {module.imageText ? (
        <div className="image-text" dangerouslySetInnerHTML={{ __html: module.imageText }} />
      ) : null}
    </section>
  );
};
export default InfographicImage;
