import React, { FC } from 'react';

import VideoIframe from 'components/VideoIframe';

import { CaseStudyVideoProps } from './models';

const CaseStudyVideo: FC<CaseStudyVideoProps> = ({
  video,
  image,
  playButtonText,
  videoClass,
  isPulledModule,
  ariaId,
}) => (
  <div className={videoClass}>
    <VideoIframe
      url={video}
      placeholderImage={image}
      playButtonText={playButtonText}
      isPulledCaseStudies={isPulledModule}
      ariaId={ariaId}
    />
  </div>
);

export default CaseStudyVideo;
