import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';

import { ToPascalCaseFunctionType } from './models';

export const toPascalCase: ToPascalCaseFunctionType = (str) =>
  startCase(camelCase(str)).replace(/ /g, '');

export const isPositiveInteger = (str: string): boolean => {
  if (typeof str !== 'string') {
    return false;
  }

  const num = Number(str);

  return !!(Number.isInteger(num) && num > 0);
};

const stringUtils = {
  toPascalCase,
  isPositiveInteger,
};

export const replaceLast = (str, pattern, replacement) => {
  const match =
    typeof pattern === 'string'
      ? pattern
      : (str.match(new RegExp(pattern.source, 'g')) || []).slice(-1)[0];
  if (!match) return str;
  const last = str.lastIndexOf(match);

  return last !== -1 ? `${str.slice(0, last)}${replacement}${str.slice(last + match.length)}` : str;
};

export default stringUtils;
