import React, { FC } from 'react';

import LinkItem from 'common/LinkItem';

import { InvestorsBannerCardProps } from './models';

import './InvestorsBannerCard.scss';

const InvestorsBannerCard: FC<InvestorsBannerCardProps> = ({ tag, title, description, link }) => (
  <div className="investors-banner__card-wrap">
    <LinkItem
      link={link}
      linkClass="investors-banner__link-hidden"
      titleClass="investors-banner__link-text"
      hasIcon={false}
      isLink
      showTitle={false}
    />
    <h2 className="investors-banner__box-heading">
      <span className="investors-banner__box-tag tag">{tag}</span>
      <span className="investors-banner__box-title h2">{title}</span>
    </h2>
    <span className="investors-banner__box-caption caption">{description}</span>
    {link ? (
      <LinkItem
        link={link}
        linkClass="investors-banner__link link link--pink"
        titleClass="investors-banner__link-text"
        hasIcon
        isLink={false}
        showTitle
      />
    ) : null}
  </div>
);
export default InvestorsBannerCard;
