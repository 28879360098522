import React, { FC } from 'react';
import classNames from 'classnames';

import { SlidesPreviewProps } from './models';

import './SlidesPreview.scss';

const SlidesPreview: FC<SlidesPreviewProps> = ({ slides, activeSlide, onActiveSlidePreview }) => {
  const activeSlidePreview = (index: number): void => {
    onActiveSlidePreview(index);
  };

  return (
    <>
      {slides?.map((slide, index) => (
        <li
          className="caseStudySlider__preview-col col--no-gutters col--md6 col--xl3"
          key={slide.articleTitle}
          role="presentation"
        >
          <div className="caseStudySlider__preview-wrap" role="presentation">
            <button
              className={classNames('caseStudySlider__preview-title h6', {
                'caseStudySlider__preview-active': activeSlide === index,
              })}
              type="button"
              role="tab"
              data-testid={slide.articleTitle}
              onClick={() => activeSlidePreview(index)}
              aria-selected={activeSlide === index}
            >
              {slide.articleTitle}
            </button>
            <div
              className={classNames('caseStudySlider__border', {
                'caseStudySlider__border--active': activeSlide === index,
              })}
            />
          </div>
        </li>
      ))}
    </>
  );
};

export default SlidesPreview;
