import React, { FC } from 'react';

import CaseStudyImage from './CaseStudyImage';
import CaseStudyVideo from './CaseStudyVideo';
import { CaseStudyVideoOrImageProps } from './models';

const CaseStudyVideoOrImage: FC<CaseStudyVideoOrImageProps> = ({
  video,
  image,
  playButtonText,
  imageClass,
  videoClass,
  isPulledModule,
  ariaId,
}) =>
  !video || video === '' ? (
    <CaseStudyImage
      image={image}
      imageClass={imageClass}
      isPulledModule={isPulledModule}
      ariaId={ariaId}
    />
  ) : (
    <CaseStudyVideo
      video={video}
      image={image}
      playButtonText={playButtonText}
      videoClass={videoClass}
      isPulledModule={isPulledModule}
      ariaId={ariaId}
    />
  );

export default CaseStudyVideoOrImage;
